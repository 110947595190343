<template>
  <div class="p-form-group">
    <div class="p-form-group-element">
      <slot />
    </div>
    <span
      v-for="item in getErrorList"
      :key="item.key"
      class="p-text-input--error"
      v-text="item.key"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  errors: {
    type: Object,
    required: true,
  },
  errorsNameList: {
    type: Array,
    required: true,
  },
})

const getErrorList = computed(() => {
  const errorList = [] as Array<{ key: string }>
  props.errorsNameList.filter((item) => {
    const errorElement = props.errors[item as string]
    if (errorElement) errorList.push({ key: errorElement })
    return item
  })

  return errorList
})
</script>
